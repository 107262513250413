<template>
  <div>
    <div v-if="editable&&isWriting" class="alert alert-info alert-dismissible fade show cursor-pointer" 
      style="margin-bottom:13px !important; padding: 10px 3px !important" role="alert"
      v-on:click="setPermitData">
      <span class="q-pl-sm q-pr-md">
        <q-icon name="o_emoji_objects" size="sm" />
      </span>
      <q-chip outline square dense color="teal" text-color="white">
        <!-- 발급 -->
        {{$label('LBL0000242', 'SWP')}}
      </q-chip>
      <!-- 정보를 허가서에서 가져오기 -->
        {{$label('LBL0000269', 'SWP')}}
    </div>
    <!-- 작업 위험성평가 -->
    <c-card title="LBL0000630">
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 발급 -->
          <c-field
            :disabled="!isWriting"
            :editable="editable"
            :data="workPermit"
            deptValue="assessWriteDeptCd"
            type="dept_user"
            label="LBL0000242"
            name="assessWriteUserId"
            v-model="workPermit.assessWriteUserId">
          </c-field>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 승인 -->
          <c-field
            :disabled="!isWriting"
            :editable="editable"
            :data="workPermit"
            deptValue="assessApprovalDeptCd"
            type="dept_user"
            label="LBL0000243"
            name="assessApprovalUserId"
            v-model="workPermit.assessApprovalUserId">
          </c-field>
        </div>
        <div class="col-12">
          <c-table
            title=""
            :columns="checkGrid.columns"
            :data="checkGrid.data"
            customDataTr
            gridHeightAuto
            hideHeader
            hideBottom
            :isTitle="false"
            :columnSetting="false"
            :isFullScreen="false"
            :isExcelDown="false"
            :filtering="false"
          >
            <template v-slot:customDataTr="props">
              <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
                <q-td
                  class="text-center th-td-style th-style"
                  :rowspan="1" :colspan="6">
                  <span>
                    <!-- 잠재위험요소 (해당 항목에 Check하고 안전조치를 취할 것) -->
                    {{$label('LBL0000009')}}
                  </span>
                </q-td>
              </q-tr>
              <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :isArray="false"
                    :disabled="!isWriting"
                    :editable="editable"
                    codeGroupCd="SOP_ASSESSMENT_CHECK_TYPE_CD1"
                    valueText="codeName"
                    valueKey="code"
                    label=""
                    name="sopAssessmentCheckTypeCd1"
                    v-model="workPermit.sopAssessmentCheckTypeCd1"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :isArray="false"
                    :disabled="!isWriting"
                    :editable="editable"
                    codeGroupCd="SOP_ASSESSMENT_CHECK_TYPE_CD2"
                    valueText="codeName"
                    valueKey="code"
                    label=""
                    name="sopAssessmentCheckTypeCd2"
                    v-model="workPermit.sopAssessmentCheckTypeCd2"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :isArray="false"
                    :disabled="!isWriting"
                    :editable="editable"
                    codeGroupCd="SOP_ASSESSMENT_CHECK_TYPE_CD3"
                    valueText="codeName"
                    valueKey="code"
                    label=""
                    name="sopAssessmentCheckTypeCd3"
                    v-model="workPermit.sopAssessmentCheckTypeCd3"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :isArray="false"
                    :disabled="!isWriting"
                    :editable="editable"
                    codeGroupCd="SOP_ASSESSMENT_CHECK_TYPE_CD4"
                    valueText="codeName"
                    valueKey="code"
                    label=""
                    name="sopAssessmentCheckTypeCd4"
                    v-model="workPermit.sopAssessmentCheckTypeCd4"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :isArray="false"
                    :disabled="!isWriting"
                    :editable="editable"
                    codeGroupCd="SOP_ASSESSMENT_CHECK_TYPE_CD5"
                    valueText="codeName"
                    valueKey="code"
                    label=""
                    name="sopAssessmentCheckTypeCd5"
                    v-model="workPermit.sopAssessmentCheckTypeCd5"
                  />
                </q-td>
                <q-td
                  class="text-center th-td-style td-style"
                  :rowspan="1" :colspan="1">
                  <c-checkbox
                    :isArray="false"
                    :disabled="!isWriting"
                    :editable="editable"
                    codeGroupCd="SOP_ASSESSMENT_CHECK_TYPE_CD6"
                    valueText="codeName"
                    valueKey="code"
                    label=""
                    name="sopAssessmentCheckTypeCd6"
                    v-model="workPermit.sopAssessmentCheckTypeCd6"
                  />
                </q-td>
              </q-tr>
            </template>
          </c-table>
        </div>
      </template>
    </c-card>
    <!-- 안전조치사항 -->
    <c-table
      ref="table"
      class="q-mt-sm"
      title="LBL0000298"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="workPermit.assessments"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      :editable="editable&&isWriting"
      selection="multiple"
      rowKey="sopWorkSopAssessmentId"
      @table-data-change="tableDataChange"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 추가 -->
          <c-btn v-if="editable&&isWriting" label="LBL0000253" icon="add" :showLoading="false" @btnClicked="add" />
          <!-- 제외 -->
          <c-btn v-if="editable&&isWriting" label="LBL0000275" icon="remove" :showLoading="false" @btnClicked="remove" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>

import { uid } from 'quasar'
export default {
  name: 'swp-assessment',

  props: {
    workPermit: {
      type: Object,
      default: function() {
        return {
          sopWorkPermitId: '',  // 작업허가서 일련번호
          plantCd: '',  // 사업장 코드
          sopName: '',  // 작업명
          mdmSopId: '',  // 안전작업 표준 일련번호_SOP에서 불러와서 관련 정보 표시후 수정
          permitNo: '',  // 허가번호_(YYYYMMDD-01)
          swpStepCd: '',  // 진행단계 코드
          applicationDeptCd: '',  // 신청인 부서코드
          applicationDeptName: '',
          applicationUserId: '',  // 신청인 ID
          applicationUserName: '',
          permitTypeCd: '',  // 허가서 구분_화기/일반
          permitDate: '',  // 허가일
          workStartTime: '', // 작업시작시간
          workEndTime: '', // 작업종료시간
          workTime: [], // 작업시간
          processCd: '',  // 작업공정
          workLocation: '',  // 작업장소
          equipmentCd: '',  // 설비번호_설비마스터
          workSummary: '',  // 작업개요
          specialRequirements: '',  // 특별요구사항
          maintenanceDeptCheckUserId: '',  // 정비부서 책임자 - 안전조치확인
          maintenanceDeptEntryUserId: '',  // 정비부서 입회자 - 안전조치확인
          workCompleteTime: '',  // 작업완료시간
          workCompleteEntryUserId: '',  // 작업완료 입회자/확인자
          workCompleteWorkUserId: '',  // 작업완료 작업자
          issuedDeptCd: '',  // 발급자 부서코드
          issuedUserId: '',  // 발급자 ID
          approvalDeptCd: '',  // 승인자 부서코드
          approvalUserId: '',  // 승인자 ID
          relationCooperation1DeptCd: '',  // 관련부서 협조자1 부서코드
          relationCooperation1UserId: '',  // 관련부서 협조자1 ID
          relationCooperation2DeptCd: '',  // 관련부서 협조자2 부서코드
          relationCooperation2UserId: '',  // 관련부서 협조자2 ID
          vendorCd: '',  // 작업업체 코드
          overWorkFlag: '',  // 연장작업 여부
          psmFlag: 'N',  // PSM 관련 여부
          sopMocId: '',  // MOC 일련번호
          assessWriteUserId: '',  // 작업위험성평가_작성자 ID
          assessApprovalUserId: '',  // 작업위험성평가_승인자 ID
          sopAssessmentCheckTypeCd1: '',  // 잠재위험요소1
          sopAssessmentCheckTypeCd2: '',  // 잠재위험요소2
          sopAssessmentCheckTypeCd3: '',  // 잠재위험요소3
          sopAssessmentCheckTypeCd4: '',  // 잠재위험요소4
          sopAssessmentCheckTypeCd5: '',  // 잠재위험요소5
          sopAssessmentCheckTypeCd6: '',  // 잠재위험요소6
          regUserId: '',  // 등록자 ID
          chgUserId: '',  // 수정자 ID
          supWorks: [],
          deleteSupWorks: [],
          checkResults: [],
          gases: [],
          gasChecks: [], // 저장용
          gasCheckVals: [], // 저장용
          deleteGasChecks: [],
          deleteGasCheckVals: [],
          maps: [],
          deleteMaps: [],
          workers: [],
          deleteWorkers: [],
          assessments: [],
          deleteAssessments: [],
          overs: [],
          deleteOvers: [],
          protectiveGears: [],
          deleteProtectiveGears: [],
        }
      }
    },
    isWriting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkGrid: {
        columns: [
          {
            name: '1',
            field: '1',
          },
          {
            name: '2',
            field: '2',
          },
          {
            name: '3',
            field: '3',
          },
          {
            name: '4',
            field: '4',
          },
          {
            name: '5',
            field: '5',
          },
          {
            name: '6',
            field: '6',
          },
        ],
        data: [{}, {}],
      },
      grid: {
        columns: [
          // 작업단계
          {
            name: 'jobStepName',
            field: 'jobStepName',
            label: 'LBL0000299',
            align: 'center',
            type: 'text',
            style: 'width:150px',
            sortable: false,
          },
          // 잠재위험요소
          {
            name: 'riskHazardName',
            field: 'riskHazardName',
            label: 'LBL0000300',
            align: 'center',
            type: 'text',
            style: 'width:250px',
            sortable: false,
          },
          {
            name: 'before',
            field: 'before',
            // 개선전
            label: 'LBL0001008',
            align: 'center',
            child: [
              {
                name: 'beforeFrequency',
                field: 'beforeFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'number',
              },
              {
                name: 'beforeStrength',
                field: 'beforeStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'number',
              },
              {
                name: 'beforeRisk',
                field: 'beforeRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          // 안전조치사항
          {
            name: 'safetyActionMeasures',
            field: 'safetyActionMeasures',
            // 개선대책<br/>(위험성 12점 이상은 필수 개선)
            label: 'LBL0000010',
            align: 'left',
            type: 'text',
            value: '',
            style: 'width:350px',
            setHeader: true,
            sortable: false,
          },
          {
            name: 'after',
            field: 'after',
            // 개선후
            label: 'LBL0001012',
            align: 'center',
            child: [
              {
                name: 'afterFrequency',
                field: 'afterFrequency',
                // 빈도
                label: 'LBL0001009',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'number',
              },
              {
                name: 'afterStrength',
                field: 'afterStrength',
                // 강도
                label: 'LBL0001010',
                align: 'center',
                style: 'width:40px',
                sortable: false,
                type: 'number',
              },
              {
                name: 'afterRisk',
                field: 'afterRisk',
                // 위험도
                label: 'LBL0001011',
                align: 'center',
                style: 'width:70px',
                sortable: false,
              },
            ]
          },
          // 조치/교육완료여부
          {
            name: 'actionEducationFlag',
            field: 'actionEducationFlag',
            label: 'LBL0000301',
            align: 'center',
            type: 'check',
            'true': 'Y',
            'false': 'N',
            value: 'N',
            style: 'width:70px',
            setHeader: true,
            sortable: false,
          },
        ],
        data: [],
        height: '400px'
      },
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
    add() {
      if (!this.workPermit.assessments) this.workPermit.assessments = [];
      this.workPermit.assessments.push({
        sopWorkSopAssessmentId: uid(),  // 작업허가서_작업위험성평가_일련번호
        sopWorkPermitId: this.workPermit.sopWorkPermitId,  // 작업허가서 일련번호
        jobStepName: '',  // 작업단계 명
        riskHazardName: '',  // 유해위험요인명
        safetyActionMeasures: '',  // 안전조치사항
        actionEducationFlag: 'N',  // 조치/교육완료여부
        beforeFrequency: null,  // 개선전 빈도
        beforeStrength: null,  // 개선전 강도
        beforeRisk: null,  // 개선전 위험도
        afterFrequency: null,  // 개선후 빈도
        afterStrength: null,  // 개선후 강도
        afterRisk: null,  // 개선후 위험도
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        editFlag: 'C'
      });
    },
    remove() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택될 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.workPermit.deleteAssessments) this.workPermit.deleteAssessments = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.workPermit.deleteAssessments, { sopWorkSopAssessmentId: item.sopWorkSopAssessmentId }) === -1) {
            this.workPermit.deleteAssessments.push(item)
          }
          this.workPermit.assessments = this.$_.reject(this.workPermit.assessments, item);
        });
        this.$refs['table'].$refs['compo-table'].clearSelection();
      }
    },
    setPermitData() {
      this.workPermit.assessWriteDeptCd = this.$_.clone(this.workPermit.issuedDeptCd)
      this.workPermit.assessWriteUserId = this.$_.clone(this.workPermit.issuedUserId)
      this.workPermit.assessApprovalDeptCd = this.$_.clone(this.workPermit.approvalDeptCd)
      this.workPermit.assessApprovalUserId = this.$_.clone(this.workPermit.approvalUserId)
    },
    tableDataChange(props, col) {
      if (!props.row[col.name]) return;
      if (col.name === 'beforeFrequency' || col.name === 'beforeStrength') {
        let c = this.checkFive(col.name, props.row['beforeFrequency'], props.row['beforeStrength']);
        if (c.bool) {
          let f = props.row['beforeFrequency'] ? Number(props.row['beforeFrequency']) : 0
          let s = props.row['beforeStrength'] ? Number(props.row['beforeStrength']) : 0
          this.$set(props.row, 'beforeRisk', (f * s))
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '1 ~ 5 사이로 지정해주세요.', // 1 ~ 5 사이로 지정해주세요.
            type: 'warning', // success / info / warning / error
          });
          this.$set(props.row, (c.type === 'f' ? 'beforeFrequency' : 'beforeStrength'), 0)
        }
      } else if (col.name === 'afterFrequency' || col.name === 'afterStrength') {
        let c = this.checkFive(col.name, props.row['afterFrequency'], props.row['afterStrength'])
        if (c.bool) {
          let f = props.row['afterFrequency'] ? Number(props.row['afterFrequency']) : 0
          let s = props.row['afterStrength'] ? Number(props.row['afterStrength']) : 0
          this.$set(props.row, 'afterRisk', (f * s))
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '1 ~ 5 사이로 지정해주세요.', // 1 ~ 5 사이로 지정해주세요.
            type: 'warning', // success / info / warning / error
          });
          this.$set(props.row, (c.type === 'f' ? 'afterFrequency' : 'afterStrength'), 0)
        }
      }
    },
    checkFive(colName, f, s) {
      if (colName.indexOf('Frequency') > -1) {
        if (f && f >= 0 && f <= 5) {
          return {
            bool: true
          };
        } else {
          return {
            bool: false,
            type: 'f',
          };
        }
      } else if (colName.indexOf('Strength') > -1) {
        if (s && s >= 0 && s <= 5) {
          return {
            bool: true
          };
        } else {
          return {
            bool: false,
            type: 's',
          };
        }
      }
    }
  }
};
</script>
<style lang="sass">
.th-td-style
  vertical-align: middle !important

.th-style
  background: #EEEEEE !important
  font-weight: 700
  width: 100%

.td-style
  width: 16.6%
</style>